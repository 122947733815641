/* 
Duplicating class selector to increase specificity to override mui-tiptap blockquote style
https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity#increasing_specificity_by_duplicating_selector
 */
.ProseMirror.ProseMirror.ProseMirror blockquote {
  padding-left: 0;
  &::before {
    background: none;
  }
}
